import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';

const SectionMultiEnumMaybe = props => {
  const { heading, options, selectedOptions } = props;

  // Only show section if we have selected options
  if (!heading || !options || !selectedOptions?.length) {
    return null;
  }

  // Filter to only show selected options
  const filteredOptions = options.filter(option =>
    selectedOptions.includes(option.key)
  );

  return (
    <section className={css.sectionMultiEnum}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        {heading}
      </Heading>
      <PropertyGroup
        id="ListingPage.amenities"
        options={filteredOptions}
        selectedOptions={selectedOptions}
        twoColumns={filteredOptions.length > 5}
        showUnselectedOptions={false}
      />
    </section>
  );
};

export default SectionMultiEnumMaybe;
